import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {PoliciesContainer} from "../../components/policies/container";
import {PoliciesMDWrapper} from "../../components/policies/md-wrapper";
import {useEffect, useState} from "react";
import {handleSetMD} from "./unaty-overview-and-definitions";
import {MarkdownViewer} from "../../components/markdown-viewer";
// https://commonmark.org/help/ markdown reference
// https://stackedit.io/app# for editing

// cookie-consent.md

export default function UnatyCommunityCodeOfConduct() {
    const [data,setData] = useState(null);
    useEffect(function () {
        // https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Fcookie-consent.md?alt=media
        fetch("https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Funaty-community-code-of-conduct-for-users.md?alt=media")
            .then(response => response.text())
            .then((dt) => {
                // jsonData is parsed json object received from url
                if(typeof dt === 'string') {
                    //
                    setData(handleSetMD(dt));
                }
            })
            .catch((error) => {

            })
    }, []);

    return (
        <Page menu>
            <Header title='Unaty Community Code of Conduct for Users'/>
            <PoliciesContainer>
                {data&&<PoliciesMDWrapper>
                    <MarkdownViewer source={data}></MarkdownViewer>
                </PoliciesMDWrapper>}
            </PoliciesContainer>
        </Page>
    )
};